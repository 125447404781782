$('#applicationForm').on('submit-success', function(event, data) {
    // do something with the response data
    console.log(data);
    alert('Pomyślnie przesłano formularz.', 'success');
});

$('#personal_data_billTypeCheckbox').on('change', function() {
    let checked = $(this).prop('checked');
    if (checked) {
        $('#personal_data_billType').attr('value', 'Faktura');
    } else {
        $('#personal_data_billType').attr('value', 'Paragon');
    }
    $('#personal_data_billPos').prop('disabled', checked);
    $('#personal_data_billPos').prop('required', !checked);
    $('#personal_data_billPos').val('');
});

$('#personal_data_pesel_checkbox').on('change', function() {
    let checked = $(this).prop('checked');
    $('#personal_data_pesel').prop('disabled', checked);
    $('#personal_data_pesel').prop('required', !checked);
    $('#personal_data_pesel').val('');
    $('.fieldset-pesel').toggleClass('d-none').prop('disabled', !checked);

    if (checked) {
        $('.main').addClass('more-fields');
    } else {
        $('.main').removeClass('more-fields');
    }
});

$('input.iban-check').each(function() {
    $(this).on('change', function() {
        let checked = $(this).val();
        $('input.iban-check').prop('checked', false);
        $(this).prop('checked', true);
        $('fieldset.fieldset-iban-record-yes').toggleClass('d-none').prop('disabled', (i, v) => !v);
        $('fieldset.fieldset-iban-record-no').toggleClass('d-none').prop('disabled', (i, v) => !v);
    });
});
