// Define imasks
const imasks = {
    type: 'imask',
    options: {
        name: {
            mask: /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]+$/,
        },
        phone: {
            mask: '000-000-000',
        },
        postal: {
            mask: '00-000',
        },
        pesel: {
            mask: '00000000000',
        },
        billNip: {
            mask: '00-000-000-00',
        },
        billNr: {
            mask: '*[*******************]',
        },
        // billPos: {
        // 	mask: 'aaa 0000 0000[00]'
        // },
        iban: {
            mask: 'PL 00 0000 0000 0000 0000 0000 0000',
        }
    }
};

export default imasks;