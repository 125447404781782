import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

const swiperProducts = new Swiper('.swiper-products', {
	modules: [Navigation, Pagination, EffectFade, Autoplay],
	loop: true,
	autoHeight: true,
	autoplay: {
		delay: 2000,
	},
	navigation: {
		nextEl: '.btn-swiper-navigation-next',
		prevEl: '.btn-swiper-navigation-prev',
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
});

const swiperWinners = new Swiper('.swiper-winners', {
	modules: [Navigation, Pagination, EffectFade, Autoplay],
	loop: true,
	autoHeight: true,
	allowTouchMove: false,
	autoplay: {
		delay: 2000,
	},
	navigation: {
		nextEl: '.btn-swiper-winners.btn-swiper-navigation-next',
		prevEl: '.btn-swiper-winners.btn-swiper-navigation-prev',
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
});